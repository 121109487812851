import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import Modal from 'react-modal';

import "./Pricing.css";


function Pricing(props) {
    const { data } = props;

    // const [modalIsOpen, setModalIsOpen] = useState(false);
    // const [currentPacketOverview, setCurrentPacketOverview] = useState('');


    // const openModal = (packetOverview) => {
    //     setCurrentPacketOverview(packetOverview);
    //     setModalIsOpen(true);
    // };

    // const closeModal = () => {
    //     setModalIsOpen(false);
    // };

    return (
        <div className="py-16 md:py-32 m-auto px-4 md:px-0 container-cs">
            <div>
                <h2 className='text-center'>{data.title}</h2>
                <p className='ss-sub pt-4' style={{ textAlign: "center" }}>{data.description}</p>
            </div>
            <div className='py-12'>
                <div className='flex flex-wrap'>
                    {(data.plan && data.plan.length > 0) && data.plan.map((pl, i) => (
                        <div className='w-full md:w-1/3 px-4 pb-4 md:pb-0' key={`pricing-plan-${i}`}>
                            <div className='pricing-wrapper'>
                                {/* <GatsbyImage
                                    image={pl.icon.localFile.childImageSharp.gatsbyImageData}
                                    alt={pl.icon.altText}
                                    className="m-auto"
                                /> */}
                                <div>
                                    <h3 className='pricing-title'>{pl.title}</h3>
                                    <p className='pricing-p'>{pl.description}</p>
                                </div>
                                <ul className='pricing-ul'>
                                    {pl.keypoints && pl.keypoints.map((pk, j) => (
                                        <li key={`keypoint-${i}-${j}`}>{pk.text}</li>
                                    ))}
                                </ul>
                                <div>
                                    <div className='pl-price' dangerouslySetInnerHTML={{ __html: pl.price }} />

                                    <div className='px-4 pt-4 flex justify-center'>
                                        <a href={pl.button.link} className='main-bttn'>
                                            {pl.button.label}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Packet Overview Modal"
                className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"  // Modal container styles
                overlayClassName="bg-black bg-opacity-50"  // Overlay styles
            >
                <div className="bg-white p-6 rounded-lg w-full max-w-lg relative ">
                    <button onClick={closeModal} style={{backgroundColor: "#1e335d", width: "42px", borderRadius: "100%" }} className="absolute top-2 right-2 p-2 rounded  text-white text-xl focus:outline-none">X</button>
                    <div className='service-editor' dangerouslySetInnerHTML={{ __html: currentPacketOverview }} />
                </div>
            </Modal> */}

        </div>
    );
}

export default Pricing;
