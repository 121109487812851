import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import Slider from "react-slick";
import readMoreIcon from "../../images/circle-right-regular.svg"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./../Home/ServicesSection.css";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    scrollable: true,
    slidesToShow: 1,
    slidesToScroll: 1
};


function ServicesSection(props) {
    const data = props.data;
    const services = props.services;
    return (
        <div
            className="container-cs px-4 md:px-8 py-16 md:py-32 m-auto"
        >
            <div className='flex flex-wrap'>
                <div className="w-full md:w-1/2 px-4 pb-8 md:pb-16 flex items-center">
                    <h2 className='w-full text-left ss-h2'>
                        <div dangerouslySetInnerHTML={{ __html: data.s2Title }} />
                    </h2>
                    <div  className='py-6 ss-sub' dangerouslySetInnerHTML={{__html: data.s2Description}} />
                </div>
                {services && services.map((service, i) => (
                    <div key={`servise-slider-${i}`} className={`md:w-1/4 px-4 w-full md:pb-8 pb-4`}>
                        <div className='service-wrapper bg-white'>
                            <div className='w-full'>
                                <div className='service-icon-float'>
                                    <GatsbyImage
                                        image={service.node.serviceAcf.serviceIcon?.localFile.childImageSharp.gatsbyImageData}
                                        alt={service.node.serviceAcf.serviceIcon?.altText}
                                        className="m-auto"
                                    />
                                </div>
                                <h3 className='text-center ss-h3'>{service.node.title}</h3>
                            </div>
                            <a href={service.node.uri.replace("service","services")} className='flex justify-center pt-6'>
                                <span>Περισσότερα</span>
                                <div>
                                    <img className='read-more-icon' src={readMoreIcon} width="17" />
                                </div>
                            </a>
                        </div>

                    </div>
                ))}
            </div>

        </div>
    );
}

export default ServicesSection;
